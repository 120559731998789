.home {
    display: flex;
    background: url('../../assets/images/bg_winter_2022.webp') no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 6rem);

    div {
        align-self: center;
        overflow: hidden;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            transform: translateY(-5%);

            @media screen and (max-height: 900px) {
                transform: translateY(-10%);
            }
        }
    }
}
