.header {
    width: 100%;
    height: 3rem;
    background-color: black;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;

    .burger {
        align-self: flex-end;
        justify-content: center;
        position: relative;
        color: white;
        z-index: 10000;
        margin-top: 0.75rem;
        margin-right: 1rem;

        .icon {
            height: 1.5rem;
            width: 1.5rem;
        }

        @media screen and (min-width: 600px) {
            display: none;
        }
    }

    nav {
        display: flex;
        width: 100%;
        height: 100%;
        visibility: visible;

        .link {
            color: white;
            text-decoration: none;
            align-self: center;
            cursor: pointer;

            &.active {
                color: #38649e;
                font-weight: bolder;
            }
        }

        @media screen and (max-width: 599px) {
            height: fit-content;
            padding: 1rem 0;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
            background: rgba(0, 0, 0, 0.8);

            &.collapse {
                visibility: collapse;
            }
        }

        @media screen and (min-width: 600px) {
            flex-direction: row;
            justify-content: space-evenly;
            list-style-type: none;
        }
    }
}
