.image {
    max-width: 90vw;
    max-height: 90vw;
    border-radius: 0.3rem;

    @media screen and (min-width: 1025px) {
        max-width: 25rem;
        max-height: 25rem;
    }
}
