.contactMe {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .contactInfo {
        color: white;
        background-color: var(--lapis);
        text-decoration: none;
        border-radius: 0.3rem;
        padding: 0.5rem;
        min-width: 8em;
        text-align: center;
        box-shadow: 0.125rem 0.125rem 0.625rem var(--grey);
    }
}
