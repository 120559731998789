.footer {
    background: black;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;

    div {
        height: 100%;
        display: flex;
        align-items: center;
    }

    div:first-child {
        padding-left: 1rem;
    }

    div:last-child {
        padding-right: 1rem;
        font-size: 1rem;
    }

    a,
    .instagram {
        color: white;
        text-decoration: none;

        &:visited {
            color: white;
        }
    }

    .instagram {
        height: 1.5rem;
        width: 1.5rem;

        .instagramIcon {
            height: 100%;
            width: 100%;
        }
    }

    .linkedin {
        font-size: 0.8rem;

        @media screen and (max-width: 600px) {
            font-size: 0.5rem;
        }
    }

    .language {
        all: unset;
        color: white;
        font-size: 1rem;
    }

    .button {
        cursor: pointer;
    }
}
