.gallery {
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 3rem;
    padding: 2rem;
    @media screen and (min-width: 1025px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
