.container {
    display: grid;
    grid-template-areas:
        'imageContainer'
        'contentContainer';
    gap: 2rem;
    padding: 1rem 2rem;

    @media screen and (min-width: 1025px) {
        gap: 5rem;
        padding: 1rem 3rem;
        grid-template-areas: 'contentContainer imageContainer';

        &.reversedContainers {
            grid-template-areas: 'imageContainer contentContainer';
        }
        grid-template-columns: 1fr 1fr;
    }

    .image {
        position: sticky;
        width: 100%;
        min-height: 15.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: black;
        border-radius: 1rem;

        @media screen and (min-width: 1025px) {
            min-height: calc(100vh - 8rem);
        }
    }

    .content {
        grid-area: contentContainer;
    }
}
