h1 {
    @media screen and (min-width: 1024px) {
        font-size: 2.5rem;
        margin: 2rem 0;
    }
}

p {
    @media screen and (min-width: 1024px) {
        font-size: 1.4rem;
    }
}
