@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&family=Space+Grotesk&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

* {
    --very-light-bg: #f2f4f6;
    --pale-blue: #a9c5de;
    --dark-grey: #191618;
    --lapis: #38649e;
    --cool-grey: #8696b5;
    --grey-translucent: #49494a9c;
    --grey: #49494a;
    --black: #000000;
    --white: #fff;
}

html,
body {
    margin: 0;
    background-color: var(--very-light-bg);
    overflow-x: hidden;
}

body {
    font-size: 16px;
    font-family: 'Space Grotesk', sans-serif;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
body::-webkit-scrollbar {
    display: none;
}
